<template>
  <div class="container py-0 product-width" v-if="productDetails.product">
    <div class="product-info" :data-attributes="categoryName">
      <div class="row ma-0">
        <VideoModal
          :dialog="openVideoModal"
          :video="videoUrl"
          @close="openVideoModal = false"
        />
        <div class="col-md-1 col-12 order-md-1 order-2 pa-0">
          <ul class="picture list-unstyled pa-0">
            <li
              v-for="(image, index) in selectedGallery?.length &&
              selectedGallery"
              :key="index"
            >
              <img
                v-lazy="image"
                width="72"
                height="67"
                translate="no"
                :alt="getImageName(image)" :title="getImageName(image)" 

                @click="changeMainImage(image, index)"
              />
            </li>
            <li>
              <div
                class="video-container"
                :style="{
                  background: `url(${
                    selectedGallery.length ? selectedGallery[0] : ''
                  })`,
                }"
                v-if="videoUrl"
                @click="selectVideo"
              >
                <img width="72" height="67" src="/img/video_icon.svg" alt="video_icon" title="video_icon" />
              </div>
            </li>
          </ul>
        </div>
        <div class="col-md-6 col-12 order-md-2 order-1 position-relative pa-0">
          <div
            class="bottom-left"
            v-show="selectedColor?.sizes[0]?.price?.discount"
            >Sale</div
          >
          <div @click="openImage(selectedImg.index)" class="top-right">
            <img src="/img/search.svg" alt="search" title="search" />
          </div>
          <div @click="openImage(selectedImg.index)" class="mainImg">
            <div
              style="width: 100%; height: 100%"
              v-show="selectedImg?.photo === 'video'"
            >
              <div
                v-if="isYoutube(videoUrl)"
                ref="youtubeContainer"
                class="center"
                v-html="videoUrl"
              >
              </div>
              <video
                v-else
                class="product-info__video"
                ref="videoRef"
                loop
                :src="'https://admin.tumar.com' + videoUrl"
              >
              </video>
            </div>
            <img
              v-show="selectedImg?.photo !== 'video'"
              v-lazy="selectedImg?.photo && selectedImg?.photo"
              class="product-info__preview"
              translate="no"
              :alt="getImageName(selectedImg?.photo)" :title="getImageName(selectedImg?.photo)" 

            />
          </div>
        </div>
        <div class="col-md-5 col-12 order-md-3 order-3 col py-0 pl-15">
          <div class="product-page">
            <nav>
              <ol class="breadcrumb pa-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ path: '/' }">Home</router-link>
                  <span class="mx-2">></span>
                </li>
                <li
                  v-for="(category, index) in productCategoriesPath"
                  :key="index"
                  class="breadcrumb-item"
                >
                  <router-link
                    :to="`/categories/${category?.url}?categoryId=${category?.id}`"
                  >
                    {{ category?.title?.ru }}</router-link
                  >
                </li>
              </ol>
            </nav>
          </div>
          <h1 class="product-ttl" itemprop="name" @click="payByPaypal()">{{
            productDetails?.product?.name?.ru
          }}</h1>
          <h3 class="product_price">
            <span
              v-if="isViewPrice"
              class="product_price notranslate"
              :class="productPrice ? 'product__old_price' : ''"
            >
              {{ isViewPrice }} {{ currency }}
            </span>
            <span v-show="productPrice && isViewPrice">/</span>
            <span v-show="productPrice" class="product_price notranslate">
              {{ productPrice }}
              {{ currency }}
            </span>
          </h3>

          <div class="stars">
            <v-icon color="#ECC366">mdi-star</v-icon>
            <v-icon color="#ECC366">mdi-star</v-icon>
            <v-icon color="#ECC366">mdi-star</v-icon>
            <v-icon color="#ECC366">mdi-star</v-icon>
            <v-icon color="#ECC366">mdi-star-half-full</v-icon>
            (412)
          </div>
          <div class="block-info-text">
            <p
              ref="description"
              itemprop="description"
              v-html="productDetails?.product?.description?.ru"
              class="mb-6"
            >
            </p>
          </div>
          <div v-show="colors[0]?.id" class="color">
            <h3 class="mb-5"
              >{{ productDetails?.variation?.title?.ru }}:
              {{ selectedColor?.title?.ru }}</h3
            >
            <ul class="list-color list-unstyled pa-0">
              <li
                v-for="color in colors"
                :key="color.index"
                :class="{ active: selectedColor?.id === color?.id }"
                class="item-color"
                @click="selectColor(color)"
                :style="{
                  background: color.photo_path
                    ? `url(${baseUrl + color?.photo_path})`
                    : 'rgb(0, 0, 0, 0.02)',
                }"
              >
                <!-- <span :class="['circle', color.class]"></span> -->
                <span>{{ color?.title }}</span>
              </li>
            </ul>
          </div>

          <div class="size" v-show="selectedColor?.sizes[0]?.size">
            <h3 class="size__title">Size:</h3>

            <div class="d-flex justify-content">
              <div class="w-100">
                <ul class="list-unstyled pa-0">
                  <li
                    itemprop="size"
                    v-for="size in selectedColor?.sizes"
                    :key="size?.id"
                    class="size-item"
                    :class="{
                      disabled: !isSizeExist(size),
                      active: isSizeExist(size) && isSizeExist(size).selected,
                      hoverable:
                        isSizeExist(size) && !isSizeExist(size).selected,
                    }"
                    @click="selectSize(size)"
                  >
                    <span>{{ size.size }}</span>
                  </li>
                </ul>
              </div>
              <!-- <div class="flex-shrink-1">
                <v-btn
                  color="#efefef"
                  style="white-space: nowrap; text-transform: unset"
                  :elevation="0"
                  :ripple="false"
                  height="40"
                >
                  Size chart
                </v-btn>
              </div> -->
            </div>
          </div>
          <v-divider></v-divider>
          <div class="row btn-add">
            <div class="col-2">
              <input
                type="number"
                :value="selectedColor?.count"
                style="border: 1px solid #f2f2f2; height: 100%"
                class="rounded w-100 px-3"
                min="1"
                @input="(e) => (selectedColor.count = e.target.value)"
              />
            </div>
            <div class="col-10 d-flex">
              <v-btn
                :disabled="!anySizeSelected"
                color="#CFEBCC"
                class="flex-grow-1"
                :elevation="0"
                :ripple="false"
                @click="addToCart"
                >Add to cart.</v-btn
              >
            </div>
          </div>
          <v-divider></v-divider>
          <div class="social_icon">
            <ul class="social_icon-item list-unstyled pa-0">
              <li
                v-for="(icon, index) in social_medias"
                @click="onShareProduct(icon.link)"
                :key="index"
                ><v-icon color="rgb(128, 128, 128)" size="14">{{
                  icon.icon
                }}</v-icon></li
              >
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4 mb-200" :data-attributes="categoryName">
      <div class="col-md-7 col-12">
        <div class="description">
          <div class="accordeon">
            <v-tabs
              hide-slider
              v-model="tab"
              color="#666666"
              class="nav nav-tabs mb-3"
            >
              <v-tab class="nav-item">Description</v-tab>
              <v-tab itemprop="aggregateRating" class="nav-item"
                >Reviews ({{ reviews?.length }})</v-tab
              >
            </v-tabs>

            <!-- <transition name="slide-fade" mode="out-in"> -->
            <div key="0" v-if="tab === 0" class="product__content">
              <v-expansion-panels
                v-model="expansion"
                class="product__accordion"
                accordion
                flat
              >
                <v-expansion-panel
                  v-for="(header, index) in expansionHeaders"
                  :key="header"
                  class="product__accordion-item"
                  :class="{
                    'border-bottom': index !== expansionHeaders?.length - 1,
                  }"
                >
                  <v-expansion-panel-header
                    hide-actions
                    class="accordion-btn px-4"
                  >
                    <span
                      class="accordion-icon"
                      :style="
                        index === expansion
                          ? 'background-image: url(/img/icons/minus-icon.svg)'
                          : 'background-image: url(/img/icons/plus-icon.svg)'
                      "
                    ></span>
                    <h2>{{ header }}</h2>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="accordion-content">
                    <div v-if="header === 'Size chart'">
                      <div
                        v-html="
                          productDetails?.product?.size_chart?.description?.ru
                        "
                      ></div>
                    </div>
                    <div
                      v-else-if="header === 'Product care'"
                      v-html="productDetails?.product?.care?.ru"
                    >
                    </div>
                    <div
                      itemprop="description"
                      v-else
                      v-html="productDetails?.product?.short_description?.ru"
                    >
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div key="1" v-else class="reviews pt-2">
              <v-container class="position-relative px-md-5">
                <form @submit.prevent="leaveReview" class="form">
                  <h6>Leave a review</h6>
                  <div class="form__input-wrapper">
                    <input
                      class="form__input"
                      type="text"
                      placeholder="Your name"
                      v-model="newReview.author"
                    />
                    <input
                      class="form__input"
                      type="email"
                      placeholder="Your email"
                      v-model="newReview.email"
                    />
                  </div>
                  <textarea
                    class="form__input-message px-5"
                    v-model="newReview.body"
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Message"
                  ></textarea>
                  <button
                    class="form__button"
                    :class="{ disabled: isReviewFormValid }"
                    type="submit"
                    >SEND</button
                  >
                </form>
                <v-overlay
                  v-if="!isAuthenticated"
                  v-model="overlay"
                  class="align-center justify-center"
                  :absolute="true"
                  opacity="0.2"
                  zIndex="3"
                >
                  <div class="text-center">
                    <h4 class="text-dark bg-white px-2 py-1 rounded"
                      >Looks like you're not authorised. Please log in to leave
                      a review</h4
                    >
                    <v-btn
                      color="#CFEBCC"
                      class="text-dark"
                      @click="onLogin"
                      small
                    >
                      Login
                    </v-btn>
                  </div>
                </v-overlay>
              </v-container>
              <v-container class="px-5">
                <v-card
                  v-for="(review, index) in reviews"
                  :key="index"
                  flat
                  :class="{ 'mb-2': index !== reviews?.length - 1 }"
                  class="bordered"
                >
                  <div class="pa-4">
                    <v-row no-gutters>
                      <v-col cols="3" md="1" class="d-flex justify-center">
                        <v-avatar size="50">
                          <v-img style="background-color: grey">
                            <template #default>
                              <v-icon color="#fff">mdi-account</v-icon>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="9" md="11" class="ps-2">
                        <v-card-title
                          class="pa-0 d-flex flex-column align-start"
                        >
                          <span style="line-height: 0.5"
                            >{{ review.author.first_name }}
                            {{ review.author.last_name }}</span
                          >
                          <small class="grey--text">
                            {{ review.created_at }}
                          </small>
                        </v-card-title>
                        <v-divider class="my-3"></v-divider>
                        <v-card-text class="pa-0">
                          {{ review.text }}
                        </v-card-text>
                        <template v-if="review.responses.length">
                          <v-divider class="my-3"></v-divider>
                          <v-card
                            v-for="(response, index) in review.responses"
                            :key="index"
                            flat
                            :class="{
                              'mb-2': index !== review.responses?.length - 1,
                            }"
                            class="bordered"
                          >
                            <div class="pa-4">
                              <h5>Shop Reply</h5>
                              <v-card-text class="pa-0">
                                {{ response.text }}
                              </v-card-text>
                            </div>
                          </v-card>
                        </template>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-12 pl-15 mt-4">
        <div
          class="recommended"
          :class="{ 'mx-n3': $vuetify.breakpoint.smAndDown }"
        >
          <CarouselComponent
            title="recommended for you"
            :items="recommendProducts"
            :product="productDetails === true"
            :api_url="baseUrl"
            @selectItem="selectItem"
            @addToCart="addToCartWithoutValidting"
            :slidesToShow="4"
            @goToProduct="
              (id) => {
                CLEAN_PRODUCT_SLUG()
                $router
                  .push({ name: 'Product', params: { id: id } })
                  .catch(() => {})
              }
            "
          />
        </div>
      </div>

      <!-- vcraft -->
      <div>
        <recursion
          v-if="productDetails?.product?.v_craft"
          :content="productDetails?.product?.v_craft[0]"
          :componentName="
            productDetails?.product?.v_craft[0]?.props?.component
              ? productDetails?.product?.v_craft[0]?.props?.component
              : productDetails?.product?.v_craft[0]?.componentName
          "
        />
      </div>
    </div>

    <base-modal :is-base-modal="isModal" :width="480" @close="closeModal">
      <template #header>
        <h2 class="product__attention-title">Attention!</h2>
      </template>
      <v-card flat>
        <div class="product__attention">
          <div class="product__attention-content">
            <div class="product__attention-text">
              You have a special item in your basket. You need to complete the
              purchase in order to buy other items.
            </div>
          </div>
        </div>
      </v-card>
      <template #footer>
        <div class="product__attention-button-block">
          <button class="product__attention-button" @click="closeModal">
            ok
          </button>
        </div>
      </template>
    </base-modal>
    <!-- <CoolLightBox
      :items="selectedGallery"
      :index="imageIndex"
      @close="imageIndex = null"
    ></CoolLightBox> -->
  </div>
  <div v-else>
    <h1 style="padding: 200px; text-align: center" class="loading"
      >Data Loading...</h1
    >
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { getImageName } from '@/utils/utils'

import cloneDeep from 'lodash.clonedeep'
import CarouselComponent from '@/view/content/store/CarouselComponent'
import BaseModal from '@/UI/BaseModal'
import CoolLightBox from 'vue-cool-lightbox'
import VideoModal from '../../content/store/VideoModal.vue'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
const {
  mapState: State_storeCart,
  mapActions,
  mapMutations,
} = createNamespacedHelpers('storeCart')
const { mapState: State_products, mapGetters: Getters_products } =
  createNamespacedHelpers('storeProducts')
const {
  mapActions: mapProductActions,
  mapState: mapProductState,
  mapMutations: product_Mutations,
} = createNamespacedHelpers('products')
const { mapState: State_Artisan } = createNamespacedHelpers('artisan')
const { mapActions: mapSizeChartActions, mapState: mapSizeChartState } =
  createNamespacedHelpers('sizeChart')
const { mapActions: mapOrderActions } = createNamespacedHelpers('orderHistory')
const { mapMutations: mapMutationsActions, mapGetters: Getters_auth } =
  createNamespacedHelpers('auth')
export default {
  name: 'Product',
  components: {
    CarouselComponent,
    VideoModal,
    BaseModal,
  },
  data() {
    return {
      isModal: false,
      imageIndex: null,
      selectedImg: null,
      baseUrl: '',
      expansion: null,
      tab: 0,
      selectedColor: null,
      newReview: {
        author: '',
        email: '',
        body: '',
      },
      sizes: [36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49],
      expansionHeaders: [
        'Product care',
        'Additional information',
        'Size chart',
      ],
      selectedProduct: null,
      selectedGallery: [],
      social_medias: [
        {
          icon: 'fas fa-circle',
          link: 'circle',
        },
        {
          icon: 'fab fa-facebook-f',
          link: 'facebook',
        },
        {
          icon: 'fab fa-x',
          link: 'twitter',
        },
        {
          icon: 'fab fa-pinterest-p',
          link: 'pinterest',
        },
        {
          icon: 'fas fa-envelope',
          link: 'envelope',
        },
      ],
      openVideoModal: false,
      overlay: true,
    }
  },
  mounted() {
    this.CLEAN_PRODUCT_SLUG()
  },
  computed: {
    ...State_storeCart(['cartItems']),
    ...State_Artisan(['artisan']),
    ...State_products(['products', 'reviews']),
    ...Getters_products(['getProductById']),
    ...Getters_auth(['isAuthenticated']),
    ...mapProductState(['productDetails', 'products', 'reviews']),
    ...mapSizeChartState(['size_chart']),
    isSpecialProduct() {
      return !!this.cartItems.filter(
        (specialProduct) => specialProduct.isSpecialProduct
      ).length
    },
    isArtisanSelected() {
      return Object.keys(this.artisan).length
    },
    currency() {
      return this.selectedColor?.products[0]?.currency?.currency?.currency
    },
    categoryName() {
      return this.productCategoriesPath[this.productCategoriesPath.length - 1]
        ?.title?.ru
    },
    selectedSizes() {
      return this.sizes.filter((size) => size.selected)
    },
    productCategoriesPath() {
      return this.productDetails?.product?.categories.map((item) => {
        return {
          title: item?.title,
          url: item.url,
          id: item.id,
        }
      })
    },
    recommendProducts() {
      if (this.products?.length) {
        return this.products?.filter(
          (i) => i?.product.id !== this.productDetails?.product?.id
        )
      } else return []
    },
    isReviewFormValid() {
      const { body } = this.newReview
      return !body
    },
    isViewPrice() {
      return this.selectedColor?.products[0]?.price?.view
    },
    productPrice() {
      if (+this.selectedColor?.products[0]?.price?.discount?.view) {
        return Number(
          +this.selectedColor?.products[0]?.price?.view -
            +this.selectedColor?.products[0]?.price?.discount?.view
        ).toFixed(2)
      }
      return ''
    },
    variations() {
      let result = []
      this.productDetails?.variation?.options?.forEach((el) => {
        result.push({
          ...el,
          articul: el?.products[0]?.vendor_code,
          gallery:
            el?.gallery?.map((item) => {
              return item
            }) || [],
        })
      })
      result = result?.map((item) => {
        return {
          ...item,
          count: 1,
          sizes: item?.products
            ?.map((e) => {
              return {
                ...e,
                size: e?.option?.filter(
                  (el) => el.variation?.title?.ru === 'Размер обуви'
                )[0]?.title?.title?.ru,
                selected: false,
                count: 1,
                // price: e?.price_view,
                quantity: e?.quantity,
              }
            })
            ?.sort((a, b) => a.size - b.size),
        }
      })
      return result
    },
    colors() {
      return this.variations?.map((el, index) => {
        return {
          title: el?.title?.ru,
          id: el.id,
          index: index + 1,
          photo_path: el?.photo_path,
        }
      })
    },
    getSetProductCount: {
      get() {
        return this.productDetails?.count
      },
      set(val) {
        this.SET_SELECTED_PRODUCT_COUNT(val)
      },
    },
    anySizeSelected() {
      // this.selectedProduct.colors.forEach((c) => sizes.push(...c.sizes))
      const isSizeAvialable = this.variations.filter((i) => i.sizes[0]?.size)
      if (!isSizeAvialable.length) {
        return 1
      } else {
        const selectedSize = this.selectedColor?.sizes.filter(
          (size) => size.selected
        )
        return selectedSize?.length
      }
    },
    videoUrl() {
      return this.productDetails.product.video_url || ''
    },
  },
  watch: {
    selectedColor: {
      immedaite: true,
      handler(val) {
        this.selectedColor = this.variations.find((item) => item.id === val.id)
      },
    },
    $route(val) {
      this.selectedImg = encodeURI(this.selectedColor?.photo_path)
    },
    productDetails(val) {
      this.setMetaTags()
    },
  },
  methods: {
    getImageName,

    ...mapActions(['setSelectedProduct', 'addCartItem', 'toggleSideCart']),
    ...mapMutations(['SET_SELECTED_PRODUCT_COUNT']),
    ...product_Mutations(['CLEAN_PRODUCT_SLUG']),
    ...mapProductActions([
      'getProductBySlug',
      'productByCatigory',
      'getReviews',
      'postReviews',
    ]),
    ...mapSizeChartActions(['getSizeChartById']),
    ...mapOrderActions(['payByPaypal']),
    ...mapMutationsActions('SET_REDIRECT_TO'),
    closeModal() {
      this.isModal = false
    },
    setMetaTags() {
      document.title = this.productDetails?.product?.name?.ru || 'Tumar.com'

      let descriptionMeta = document.querySelector('meta[name="description"]')
      if (!descriptionMeta) {
        descriptionMeta = document.createElement('meta')
        descriptionMeta.name = 'description'
        document.head.appendChild(descriptionMeta)
      }
      descriptionMeta.content =
        this.productDetails?.product?.seo_description?.ru || ''

      let keywordsMeta = document.querySelector('meta[name="keywords"]')
      if (!keywordsMeta) {
        keywordsMeta = document.createElement('meta')
        keywordsMeta.name = 'keywords'
        document.head.appendChild(keywordsMeta)
      }
      keywordsMeta.content = this.productDetails?.product?.seo_keys?.ru || ''
    },
    removeMetaTags() {
      document.title = 'Tumar.com'

      const descriptionMeta = document.querySelector('meta[name="description"]')
      if (descriptionMeta) descriptionMeta.remove()

      const keywordsMeta = document.querySelector('meta[name="keywords"]')
      if (keywordsMeta) keywordsMeta.remove()
    },
    selectSize(size) {
      this.selectedColor.sizes.forEach((s) => {
        if (s === size) {
          s.selected = !s.selected
        } else {
          s.selected = false
        }
      })
    },
    onShareProduct(link) {
      const url = encodeURIComponent(window.location.href)
      if (link === 'facebook') {
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
        window.open(shareUrl, '_blank')
      } else if (link === 'twitter') {
        const shareUrl = `https://twitter.com/intent/tweet?url=${url}`
        window.open(shareUrl, '_blank')
      } else if (link === 'pinterest') {
        const imageUrl = encodeURIComponent(
          this.baseUrl + this.productDetails?.product?.photo
        )
        const description = encodeURIComponent(
          this.$refs.description.textContent
        )
        const shareUrl = `https://www.pinterest.com/pin/create/button/?url=${url}&media=${imageUrl}&description=${description}`
        window.open(shareUrl, '_blank')
      } else {
        return
      }
    },
    changeMainImage(image, index) {
      this.$refs.videoRef.pause()

      this.selectedImg = {
        photo: encodeURI(image),
        index,
      }
    },
    selectColor(color) {
      this.selectedColor = {}
      this.selectedGallery = []
      this.selectedImg = {}
      this.selectedColor = this.variations.find((item) => item.id === color.id)
      this.selectedImg = {
        photo: this.baseUrl + this.selectedColor?.product_gallery[0] || '',
      }
      this.selectedGallery = this.selectedColor?.product_gallery?.map((el) => {
        return this.baseUrl + el
      })
    },
    addToCart() {
      if (this.isArtisanSelected || this.isSpecialProduct) {
        this.isModal = true
        return
      }

      if (!this.anySizeSelected) {
        return
      }
      const payload = {
        ...cloneDeep(this.productDetails),
        variation: [],
      }
      if (this.selectedColor.sizes[0]?.size) {
        const selectedSizes = this.selectedColor.sizes.filter((s) => s.selected)
        payload.variation = cloneDeep(this.selectedColor)
        payload.size = selectedSizes.map((size) => ({
          ...size,
          count: Number(this.selectedColor.count),
        }))[0]
      } else if (this.selectColor?.title?.ru) {
        payload.variation = cloneDeep(this.selectedColor)
        payload.size = {
          ...this.selectedColor?.sizes[0],
          count: Number(this.selectedColor.count),
        }
      } else {
        payload.variation = cloneDeep(this.selectedColor)
        payload.size = {
          ...this.selectedColor?.sizes[0],
          count: Number(this.selectedColor.count),
        }
      }
      this.addCartItem(payload)
      this.toggleSideCart(true)

      this.selectedColor.sizes.forEach((s) => (s.selected = false))
      this.selectedColor.count = 1
    },
    isSizeExist(size) {
      return this.selectedColor.sizes.find((item) => item === size)
    },
    selectItem(item) {
      this.setSelectedProduct(item)
      this.$router.push({ name: 'Product', params: { id: item.id } })
    },
    isYoutube(content) {
      if (!content) {
        return false
      }

      return content.includes('<iframe')
    },
    selectVideo() {
      this.selectedImg = { photo: 'video' }

      this.$refs.videoRef.play()
    },
    addToCartWithoutValidting(item) {
      const copy = cloneDeep(item)
      copy.colors = copy?.colors[0]
      copy.colors.sizes = [copy?.colors?.sizes[0]]
      this.addCartItem(copy)
      this.toggleSideCart(true)
    },
    openImage(index) {
      this.imageIndex = index
    },
    leaveReview() {
      if (!this.isAuthenticated) this.onLogin()
      const { body } = this.newReview
      if (!body) {
        return
      }
      const newReview = {
        product_id: this.productDetails.product.id,
        product_url: `${window.location.origin}${this.$route.fullPath}`,
        text: body,
      }

      this.postReviews(newReview)

      this.newReview.author = ''
      this.newReview.body = ''
      this.newReview.email = ''
    },
    onLogin() {
      localStorage.setItem('goBackLogin', JSON.stringify(this.$route.path))
      return this.$router.push({ name: 'login' })
    },
  },
  async created() {
    await this.getProductBySlug({
      slug: this.$route.params.id,
    })

    await this.productByCatigory({
      id: this.productDetails.product.categories[0]?.id,
    })
    await this.getReviews({ productId: this.productDetails.product.id })
    this.baseUrl = this.$store.state.auth.API_URL
    this.selectedColor = this.variations[0]
    this.selectedGallery = this.productDetails?.product?.gallery || []
    this.selectedGallery.push(this.productDetails.product?.photo)
    this.selectedGallery = this.selectedGallery?.map((item) => {
      return this.baseUrl + item
    })
    this.selectedImg = {
      photo: encodeURI(this.selectedGallery[this.selectedGallery?.length - 1]),
      index: this.selectedGallery?.length - 1,
    }
  },
  beforeDestroy() { 
  this.removeMetaTags()
}
}
</script>
<style lang="scss">
.product {
  &__attention {
    &-title {
      font-size: 20px;
      text-align: center;
      margin: 0;
    }

    &-content {
      min-height: 50px;
      text-align: center;
    }

    &-button {
      &-block {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      background-color: rgb(242, 242, 242);
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      border-width: 0;
      padding: 5px 10px;
      border-style: unset;
      min-width: 80px;
    }
  }
}

.description {
  .v-slide-group__wrapper {
    position: relative;
    z-index: 1;
  }

  .nav-tabs {
    border-bottom: unset;
    position: relative;
  }

  .v-tabs-bar__content {
    .v-tab {
      border-bottom: 2px solid transparent;
    }

    .v-tab.v-tab--active {
      position: relative;
      border-bottom: 2px solid;
    }
  }

  .v-tabs-slider {
    border-radius: 3px;
  }

  .v-tabs.nav.nav-tabs::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #dee2e6;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
}
</style>
<style lang="scss" scoped>
.reviews {
  display: flex;
  flex-direction: column;
}

.product-info__video {
  width: 100%;
  margin: auto 0;
}

.video-container {
  background-size: cover !important;
}

.item-color span {
  opacity: 0;
}

.item-color {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.product__old_price {
  text-decoration: line-through;
  font-size: 24px;
}

.item-color.active,
.item-color:hover {
  border: 1px solid #6e6e6e;
}

.v-expansion-panel-content__wrap {
  padding: 0 24px 0px;
}

.size-item.hoverable span:hover,
.size-item.active span {
  color: white;
  cursor: pointer;
}

.size-item.hoverable span:hover {
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}

.size-item.active span {
  background-color: #423f3d;
  border-color: #423f3d;
}

.size-item.disabled {
  cursor: no-drop;

  span {
    position: relative;
    color: #d3d2d2;
  }

  span:before {
    content: '';
    position: absolute;
    background-color: #f2f2f2;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 1px;
    height: 140%;
    top: 50%;
    left: 50%;
    overflow: hidden;
  }
}

.product-page {
  .breadcrumb {
    background-color: unset;

    .breadcrumb-item {
      a {
        color: #818181;
      }
    }
  }
}

.breadcrumb-item {
  padding-left: 0;

  &:before {
    content: none;
  }

  span {
    color: #818181;
  }
}

.product-info {
  &__preview,
  .picture li {
    cursor: pointer;
  }
}

.mainImg {
  display: flex;
  justify-content: center;
  width: 100%;

  // max-height: 410px;
  img {
    max-width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
}

.top-right {
  img {
    cursor: pointer;
  }
}

.size__title {
  color: #666666;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 14px;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  h2 {
    transition: 0.4s;
    color: #423f3d;
  }
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: unset;
}

.v-tab {
  text-transform: unset;
  font-size: 16px;
  letter-spacing: unset;
}

.contact-us__right {
  width: unset;

  .form__input-message {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.border-bottom {
  border-bottom: 1px solid #f2f2f2;
}

.bordered {
  border: 1px solid #f2f2f2;
}

.v-expansion-panel-content.accordion-content
  .v-expansion-panel-content__wrap
  > div {
  padding-left: 31px;
}

.v-expansion-panel-content.accordion-content {
  padding-bottom: 0;
}

// ul.picture.list-unstyled {
//   height: 410px;
//   overflow: scroll;
// }

// .size ul.list-unstyled {
//   max-height: 110px;
//   overflow: scroll;
// }

// .size ul.list-unstyled:after {
//   content: '';
//   background: rgb(2, 0, 36);
//   background: linear-gradient(
//     360deg,
//     rgb(2 0 36 / 14%) 0%,
//     rgb(2 0 36 / 6%) 21%,
//     rgba(255, 255, 255, 0) 100%
//   );
//   width: 100%;
//   height: 40px;
//   position: sticky;
//   bottom: 0;
// }

.size ul.list-unstyled {
  position: relative;
}

.product-info {
  margin-top: 127px;
}

.product-ttl {
  font-size: 40px;
  color: #423f3d;
  font-weight: bolder;
}

ol.breadcrumb.pa-0 {
  margin: 0;
}

.product-width,
main .product-width {
  width: 1185px;
  max-width: 1185px !important;
}

.color h3 {
  color: #666666;
  font-size: 16px;
  font-weight: 500;
}

.item-color span {
  position: relative;
  // border-radius: 50%;
  border: 1px solid transparent;
}

.size-item {
  width: 50px;
  height: 50px;
  margin: 0 9px 9px 0;
}

.size-item span {
  display: inline-block;
  padding: 7px 12px;
  border: 1px solid #c1c1c1;
  color: #423f3d;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 2.3;
}

ul.picture.list-unstyled img {
  object-fit: cover;
}

.recommended h2 {
  margin-bottom: 0;
}

.mb-200 {
  margin-bottom: 200px;
}

.recommended .inner-image {
  height: 100px !important;
}

.p_text.bordered.border-top-none > p {
  height: 20px;
  overflow: hidden;
}

@media (max-width: 600px) {
  h1.loading {
    padding: 0 !important;
    margin-top: 200px;
    margin-bottom: 200px;
    font-size: 20px;
  }

  .product-info {
    margin-top: 80px;
  }

  h1.loading {
    padding: 0 !important;
    margin-top: 200px;
    margin-bottom: 200px;
    font-size: 20px;
    width: 240px;
    height: 36px;
    overflow: hidden;
  }

  .col-md-5.col-12.order-md-3.order-3.col.py-0.pl-15 {
    padding: 0 !important;
    position: inherit;
  }

  .product-page ol.breadcrumb.pa-0 {
    position: absolute;
    top: 0;
  }

  h1.product-ttl {
    position: absolute;
    top: 25px;
    font-size: 30px !important;
  }

  h3.product_price {
    position: absolute;
    right: 0;
    top: 25px;
  }

  .col-md-6.col-12.order-md-2.order-1.position-relative.pa-0 {
    margin-top: 40px;
  }

  .stars {
    position: absolute;
    top: -5px;
    right: 0;
  }

  .description img {
    width: 100% !important;
  }

  .row.mt-4.mb-200 .col-md-5.col-12.pl-15.mt-4 {
    padding: 10px 20px !important;
    margin: 0 !important;
  }

  .social_icon ul li:first-child,
  .social_icon ul li:last-child {
    display: none;
  }
}
</style>
